import React, { Component } from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Banner from './common/Banner';
import { Link } from 'react-router-dom';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';

export default class sent extends Component {
    render() {
        return (
            <div>
                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Message Sent Successfully" image="banners/about.jpeg" />
                <hr /><h6 style={{ textAlign: "center" }}><Link to="/">Go Back to Home</Link></h6><hr />
                <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData}/>
            </div>
        )
    }
}