import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Banner from '../components/common/Banner';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';

const About = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" nClass="w_menu" />
            <Banner title="About Us" image="banners/about.jpeg" />
            <section className="get_started_three sec_pad">
                <div className="container">
                    <div className="sec_title text-center mb_70">
                        <h2 className="f_p f_size_30 l_height45 f_600 t_color3">MAPEO Overseas Educational Consultancy</h2>
                    </div>
                    <div className="intregration_content">
                        <p className='justify'>MAPEO is a Overseas Education Consultancy located in Mysore to provide quality advice and guidance to students desirous of pursuing higher studies abroad. The MAPEO team has recruited many students to  institutions in Australia, Canada, France, , Germany Ireland, Latvia, Litvia, Netherlands New Zealand, New Zealand , Poland, Singapore, Sweden, UK and USA. It provides not only guidance to choice of University, location, programme, also co-relate these to the requirements of local students. We are reputed as being a specialist consultancy, guiding both students and parents flawlessly and comprehensively in their ambitions and aiming to study abroad. We have over 2,000 satisfied clients and students in Mysore.</p>
                        <div className="section-title with-desc text-left clearfix">
                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                <h4 className="title">Our Guidance</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/about/about-1.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-7 justify" style={{ margin: "auto", paddingTop:"1rem" }}>
                                <ul className="ttm-list ttm-list-style-icon">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> We offer Quality Advice on Quality Higher Education.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> We assist you to get admission in any University in Australia, Canada, France, Germany, Ireland, Latvia, Lithuania, Netherlands, New Zealand, Poland, Singapore, Sweden, UK and USA.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Many Students were helped to gain entry to programmes abroad.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Free advice on wide range of topics from Undergraduate & postgraduate programmes application procedures, visas, scholarships and many other topics.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Quick in getting admission decisions after submitting the application.</li>
                                </ul>
                            </div>
                        </div>
                        <p className="about-para paddingtop-2">With over 22 years of experience, we provide a wide range of following services concerning overseas education and immigration.<br/></p>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-style-icon">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Coaching and Training</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> University and Course Selection</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Countries and Destinations</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Assistance for Application</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-style-icon">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Financial aid information</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Guidance for Visa</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Flight Booking</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i> Post arrival briefing, aid and accommodation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData} />
        </div>
    )
}
export default About;