import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesUSA extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">USA (United States of America)</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-1.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para">The USA is one of the World’s largest and the best education systems, with programs across all disciplines. All the qualifications awarded by the USA universities are recognized throughout the world for its academic excellence.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">The USA offers great educational choices and research universities that are dedicated to higher education and research.</p>
                                                <p className="about-para">It is the world leader in Technology Innovation.</p>
                                                <p className="about-para">It is the home for the most prestigious IVY League universities.</p>
                                                <p className="about-para">The USA is one of the top destinations for international students also for its cultural experience. Its multicultural environment helps them to build relationships with people from around the world.</p>
                                                <p className="about-para">Many universities give an option to take up internships to kick start the student’s professional career. The internships during the college help them to gain Hands-on Experience which will further help them to gain employment post their graduation.</p>
                                                <p className="about-para">The USA offers good financial assistance and scholarships for international students.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h5 className="title">Employbility</h5>
                                        </div>
                                    </div>
                                    <p className="about-para">Being the world leader in proving quality education, the USA does offer job opportunities to students.</p>
                                    <p className="about-para">Any student with a valid F1 visa has the option to take up on-campus part-time work up to 20 hours a week during years of study. Universities provide options to work in the form of Teaching Assistantships/Research Assistantships.</p>
                                    <p className="about-para">Many students prefer for internships and practical training which will prepare them to face the employers.</p>
                                    <p className="about-para">After 9 months of study in the USA, students have an option to take up OPT (off-campus work placement). To get an OPT approval the student need to secure employment option in a field directly related to his field of study. OPT is permitted for up to 12 months in total.</p>
                                    <p className="about-para">For students who have a work placement training as a part of their programs have an option to take up CPT (Curricular Placement Training) to work off-campus full time. The CPT Job offer must be in the major of study. Any student who takes up the CPT for 12months or more is not eligible to take up the OPT option.</p>
                                    <p className="about-para">OPT can be taken up before or after completing the program that the student is enrolled for.</p>
                                    <p className="about-para">Certain students who receive their degrees in Science, Technology, Engineering, and Mathematics (STEM)may be eligible for an additional OPT period of 24 months. The student can contact their University DSO to opt and apply for their CPT/OPT options.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
