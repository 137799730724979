import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class CountriesSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to={'/Countries-USA'}>USA</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-UK'}>UK</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-CANADA'}>Canada</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-Australia'}>Australia</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-Germany'}>Germany</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-Ireland'}>Ireland</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-NewZealand'}>New Zealand</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-Singapore'}>Singapore</NavLink></li>
                    <li><NavLink className="nav-link" to={'/Countries-France'}>France</NavLink></li>
                </ul>
            </div>
        )
    }
}