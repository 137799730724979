import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';

const NotFound = () => (
  <div className="body_wrapper">
    <CustomNavbar mClass="menu_four" nClass="w_menu" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error.png")} alt="" />
          <h2>Error. We can’t find the page you’re looking for.</h2>
          <p>Sorry for the inconvenience.</p>
          <a href="/" className="about_btn btn_hover">Back to Home Page <i className="arrow_right"></i></a>
        </div>
      </div>
    </section>
    <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData} />
  </div>
);
export default NotFound;
