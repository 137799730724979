import React, { Component } from 'react';

class PrototypeFooter extends Component {
    render() {
        var { rclass } = this.props;
        return (
            <footer className={`payment_footer_area ${rclass}`}>
                <div className="footer_top_six">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="f_widget company_widget">
                                    <h3 className="f-title f_600 w_color f_size_18 mb_40">Contact Us</h3>
                                    <p className="mt_40">Mapeo Overseas Education, <br /> 12/1A, 1st Floor, 9th Main, 5th Cross,<br /> Saraswathipuram, Mysore,<br/>Karnataka - 570009<br/>Phone : +91 99024 35156, +91 80508 20711<br/>© 2023 Desing by <br /><a href="https://www.shoonyeka.com" target="_blank">Shoonyeka Solutions Pvt Ltd</a></p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-6">
                                <div className="f_widget about-widget">
                                    <h3 className="f-title f_600 w_color f_size_18 mb_40">About Us</h3>
                                    <p>MAPEO Overseas Educational Consultants (consultancy), in MYSORE has been counseling and assisting students over the years, to study in Australia, Canada, France, Germany, Ireland, Latvia, Lithuania, Netherlands, New Zealand, Poland, Singapore, Sweden, UK and USA. The exciting prospect of studying abroad offers an excellent opportunity for personal growth and development. We have been guiding students, in and around Mysore along with their families. Our guidance and support are available through every step of the decision-making process, until they secure admissions and get their visas.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default PrototypeFooter;