import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesAustralia extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Australia</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-4.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why AUSTRALIA:</b>  Australia is currently the third most popular destination for international students in the English-speaking world.</p>
                                                <p className="about-para">For a country with a relatively small number of universities, Australia offers a truly world-class</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">education and regularly features in global rankings. Seven Australian universities placed in the top 100 in the latest QS World University Rankings. One of the most appealing aspects of Australian Universities for international students is the quality of scientific research. Australia is at the forefront of new technology and innovations. Australian qualifications are recognized by employers and leading educational institutions around the world.</p>
                                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">Students get the part-time job of 24 hours a week at retail, hospitality, tourism, agriculture, and administration easily and even within the campus of the University.</p>
                                                <p className="about-para">The Post-Study Work stream offers extended options for working in Australia to eligible graduates of a higher education degree. Under this stream, successful applicants are granted a visa with a visa period of two, three- or four-years duration, depending on the highest educational qualification they have obtained.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
