const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id: 1,
            shape: 'icon_shape1.png',
            icon: 'ti-panel',
            text: 'Export Presets',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id: 2,
            shape: 'icon_shape2.png',
            icon: 'ti-layout-grid2',
            text: 'Grid and Guides',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id: 3,
            shape: 'icon_shape3.png',
            icon: 'ti-gallery',
            text: 'Prototyping',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id: 4,
            shape: 'icon_shape4.png',
            icon: 'ti-fullscreen',
            text: 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safer',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flexible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Convinient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles: 'SELECTION  OF UNIVERSITY',
            HRdescription: "Assistance to make the right choice that would suit personal, academic and financial profile of the candidate.",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '2',
            HRtitles: 'CAREER COUNSELLING AND COURSE SELECTION',
            HRdescription: "We guide the candidate to find  solutions, suited to candidate’s questions and his/her profile or parameters ensuring him/her choose the best career path. Personal guidance to choose courses that perfectly fit his/her career or personal goals",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_two',
        },
        {
            id: '3',
            HRtitles: 'ADMISSION GUIDANCE',
            HRdescription: "Special attention to candidate’s applications and highlighting the areas essential for a well-presented, error free application. We assist with   reference letters and statement of purpose.",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_three',
        },
        {
            id: '4',
            HRtitles: 'VISA ASSISTANCE',
            HRdescription: "We help the candidate in entire visa process like filling up application, preparing financial statement and also guidance for training about mock interviews and much more. We are regularly advised by the consulates about the latest visa documentation, rules and have an extremely high success rate.",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_four',
        },
        {
            id: '5',
            HRtitles: 'TRAVEL ASSISTANCE',
            HRdescription: "We take care of booking flights well in advance to allow ideal departure dates with concession   air fares.",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_five',
        },
        {
            id: '6',
            HRtitles: 'BANK LOAN ASSISTANCE',
            HRdescription: "We have list of nationalized and Private (Recognized by embassies)  Banks offering bank loan at reasonable rates with or without collateral security. We also help you with guiding about the same starting from application, process required documentation, and all processes at the Bank",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_one',
        },
        {
            id: '7',
            HRtitles: 'ACCOMMODATION',
            HRdescription: "We assure students getting off-campus or on-campus accommodation within their budget in safety places around the University",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_two',
        },
        {
            id: '8',
            HRtitles: 'STANDARDISED TESTS',
            HRdescription: "We run coaching for IELTS, TOEFL GRE and GMAT and these are taught by expert trained teachers. Every student is scoring above his/her expectations in the aforesaid exams. We also assist the students to register on-line for all exams.",
            Hicon: 'panel',
            rclass: 'pr_70',
            iclass: 'icon_three',
        },
    ],
    rpost: [
        {
            id: '01',
            image: 'blog-grid/post_1.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '02',
            image: 'blog-grid/post_2.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        {
            id: '03',
            image: 'blog-grid/post_3.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'jan 10, 2019'
        },
        {
            id: '04',
            image: 'blog-grid/post_4.jpg',
            ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
            admin: 'Admin',
            date: 'March 1, 2019'
        }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
}
export default ServiceData;