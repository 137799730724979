import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesUK extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">UK (United Kingdom)</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-2.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why UK:</b>  United Kingdom of Great Britain and Northern Ireland</p>
                                                <p className="about-para">It comprises England, Scotland, Wales and Northern Ireland. The United Kingdom includes the island of Great Britain, the north-eastern part of the island of</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">Ireland, and many smaller islands within the British Isles.</p>
                                                <p className="about-para">UK is now the second most popular destination for international students, behind the US. QS World University Ranking says “Four of the top ten universities on the planet are in the UK”.</p>
                                                <p className="about-para">The cost of degrees for an international student in the UK can be generally lower compared to the USA and other countries. The degree will generally take less time to complete in the UK than in other countries, this will also save you money.</p>
                                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">Students are allowed to work 20 hours a week and during vacation 40 hours week. After completion of graduation students can avail post-work permit of 2 years.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
