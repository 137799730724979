import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesCanada extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Canada</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-3.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why UK:</b>  Canada’s universities maintain high standards of academic excellence and are consistently recognized in top international rankings. The tuition fees in Canada are some of the lowest compared to English-speaking countries.</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">Canada is at the international forefront of computer and information technology, particularly in telecommunications, aerospace engineering, lasers, biotechnology, medical devices, ocean and environmental, Health services and several others.</p>
                                                <p className="about-para">Through its innovative School Net program, Canada was the world’s first country to connect its schools and libraries to the internet.</p>
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">There are various part-time jobs available in all the sectors across hospitality/catering to call center to banking and finance etc. With few exceptions, it is very unlikely that you will find a part-time job related to your course of study.  All international students are eligible to work 20 hours per week during studies; 40 hours per week during vacations.</p>
                                                <p className="about-para">To work in Canada after graduation, the student must apply for a work permit under the Post-Graduation Work Permit Program (PGWPP).</p>
                                                <p className="about-para">The PGWPP allows students who have graduated from a participating Canadian post-secondary institution to gain valuable Canadian work experience. Skilled Canadian work experience gained through the PGWPP helps graduates qualify for permanent residence in Canada through Express Entry.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
