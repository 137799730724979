import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesGermany extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Germany</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-5.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why Germany:</b>  Unlike many other countries, no tuition fees are charged for undergraduate as well as for Masters degrees at public universities in Germany.</p>
                                                <p className="about-para">Technical universities or Hochschild, as Germans call them, focus mainly on technology and engineering</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">valuing not only the art of theory but also the art of practice. In fact, the emphasis in these institutions is on the practical skills, that the students will gain during their studies.</p>
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">The student can work 24 hours a week and 40 hours during holidays while studying. They can easily meet their living expenses with the part-time earnings. After finishing studies, The German Law allows international graduates to stay for an additional 18 months to seek work, and you may even end up staying longer.</p>
                                                <p className="about-para">Successful graduation at a German university opens a range of opportunities in the German job market for international graduates. Before your resident permit expires, you have to take the next step that is to apply for a resident permit, which will allow you to stay and then work in Germany.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
