import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Banner from '../components/common/Banner';
import HRService from '../components/Service/HRService';
import ServiceData from '../components/Service/ServiceData';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';

const Services = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" nClass="w_menu"/>
            <Banner title="Our Services" image="banners/contact.jpeg" />
            <HRService ServiceData={ServiceData}/>
            <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData}/>
        </div>
    )
}
export default Services;