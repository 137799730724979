import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import Dashboard from "./Pages/Dashboard";
import About from "./Pages/About";
import Services from "./Pages/Services";

import CountriesUSA from "./components/Countries/CountriesUSA";
import CountriesUK from "./components/Countries/CountriesUK";
import CountriesCanada from "./components/Countries/CountriesCanada";
import CountriesAustralia from "./components/Countries/CountriesAustralia";
import CountriesGermany from "./components/Countries/CountriesGermany";
import CountriesIreland from "./components/Countries/CountriesIreland";
import CountriesNewZealand from "./components/Countries/CountriesNewZealand";
import CountriesSingapore from "./components/Countries/CountriesSingapore";
import CountriesFrance from "./components/Countries/CountriesFrance";

import Training from "./Pages/Training";
import Contact from "./Pages/Contact";

import NotFound from "./Pages/404";

import sent from './components/sent';
import ScrollToTopRoute from "./ScrollToTopRoute";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path="/" component={Dashboard} />
          <ScrollToTopRoute path="/About" component={About} />
          <ScrollToTopRoute path="/Services" component={Services} />

          <ScrollToTopRoute path="/Countries-USA" component={CountriesUSA} />
          <ScrollToTopRoute path="/Countries-UK" component={CountriesUK} />
          <ScrollToTopRoute path="/Countries-Canada" component={CountriesCanada} />
          <ScrollToTopRoute path="/Countries-Australia" component={CountriesAustralia} />
          <ScrollToTopRoute path="/Countries-Germany" component={CountriesGermany} />
          <ScrollToTopRoute path="/Countries-Ireland" component={CountriesIreland} />
          <ScrollToTopRoute path="/Countries-NewZealand" component={CountriesNewZealand} />
          <ScrollToTopRoute path="/Countries-Singapore" component={CountriesSingapore} />
          <ScrollToTopRoute path="/Countries-France" component={CountriesFrance} />

          <ScrollToTopRoute path="/Training" component={Training} />
          <ScrollToTopRoute path="/Contact" component={Contact} />

          <ScrollToTopRoute path="/Sent" component={sent} />

          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
