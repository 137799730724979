import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesNewZealand extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">New Zealand</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-7.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why NEW ZEALAND:</b>  You get a British-based education (due to their British influences) for a percentage of the cost. Teaching standards are high and with small class sizes you will enjoy high levels of personal attention.</p>
                                                <p className="about-para">Employers around the world respect New Zealand’s education system for its ability to balance academic</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">achievements with skills, producing creative, flexible thinkers who are competent at both practical and theoretical levels. The education system is regulated with strong quality assurance systems across the board. It creates a consistency that gives you the flexibility to pick the institution the student wants, in the city or town that interests the student most, knowing that the student will get a quality education.</p>
                                                <p className="about-para">The country has a comparatively low cost of living, abundant fresh food at reasonable prices and a wide variety of student accommodation options.</p>
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">There are various part-time jobs available in all the sectors across hospitality/catering to call center to banking and finance and etc. The students can work 20 hours per week during studies; 40 hours per week during vacations while studying.</p>
                                                <p className="about-para">During post study work visa of one year, allows the student to find a job that is relevant to your qualification. After you have found a job relevant to your qualification you can apply for a Post Study Work Visa (Employer Assisted). Obtaining a Master's or Doctoral level qualification in New Zealand, and studied here for at least 30 weeks you can work in New Zealand for 3 years.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
