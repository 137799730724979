import React from 'react';
import Reveal from 'react-reveal/';
import Slider from 'react-slick';

import CustomNavbar from '../components/CustomNavbar';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';

const Dashboard = () => {

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    };

    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" nClass="w_menu" />

            {/*Banner Start*/}
            <section className="startup_banner_area_three">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 paddingtop-5">
                            <Reveal bottom cascade duration={1100}>
                                <div className="startup_content_three">
                                    <h2><span>MAPEO</span><br /><small>Book Your Consultation</small></h2>
                                    <form className="contact_form_box" method="post" action="homeform.php">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group text_box">
                                                    <input type="text" placeholder="Name" name='name'/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group text_box">
                                                    <input type="text" placeholder="Email" name='email'/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group text_box">
                                                    <input type="text" placeholder="Phone" name='phone'/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group text_box">
                                                    <input type="text" placeholder="City" name='city'/>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn_six slider_btn">Submit</button>
                                    </form>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
                <div className="stratup_app_screen">
                    <Reveal effect="slideInnew"><img className="phone" src={require('../img/new/0222.png')} alt="" /></Reveal>
                    <Reveal effect="slideInnew"><img className="laptop" src={require('../img/new/0111.png')} alt="" /></Reveal>
                </div>
            </section>
            {/*Banner End*/}

            {/*Text Section Start*/}
            <React.Fragment>
                <section className="get_started_three paddingtop-4-mob">
                    <div className="container">
                        <div className="sec_title text-center mb_70">
                            <h2 className="f_p f_size_30 l_height45 f_600 t_color3">About<br />MAPEO Overseas Educational Consultancy</h2>
                        </div>
                        <div className="intregration_content" style={{ marginTop: "-2rem" }}>
                            <p className='justify'>MAPEO Overseas Educational Consultants (consultancy), in MYSORE has been counseling and assisting students over the years, to study in Australia, Canada, France, Germany, Ireland, Latvia, Lithuania, Netherlands, New Zealand, Poland, Singapore, Sweden, UK and USA. The exciting prospect of studying abroad offers an excellent opportunity for personal growth and development. We have been guiding students, in and around Mysore along with their families. Our guidance and support are available through every step of the decision-making process, until they secure admissions and get their visas. Our services includes a complete profile analysis which helps identify the best course and university for the students considering their previous qualifications, career goals and interest</p>
                            <p className='justify'>We provide specialized services, which includes information about universities. Processing application forms and other required documents, bank loan assistance, visa documentation and most importantly, Coaching for GRE, GMAT, IELTS, TOEFL & German language</p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
            {/*Text Section End*/}

            {/*Testimonial Section Start*/}
            <section className="ttm-row only-testimonial-section bg-img11 pb-0 res-991-pb-0 clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title with-desc text-center clearfix">
                                <div className="title-header">
                                    <h2 className="title">Our Testimonial</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Slider className="testimonial-slide style1 pt-0 slick_slider res-991-pb-0" {...settings}>
                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_1.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote> I would also like to thank Mapeo Staff   who helped me coaching TOEFL and entire procedure for admission and visa to join University of Melbourne. To sum up, I would recommend Mapeo Overseas for anyone for their overseas studies.</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Poorvith Gowda Gavenahalli Divakar</h6>
                                            <label>University Of Melbourne, Australia</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_2.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote>I want to express my gratitude to Mapeo Consultancy for their exceptional support in obtaining my admission and visa to join University of Surrey,UK. Their team went above and beyond to ensure a smooth and hassle-free process. The guidance and assistance I received were invaluable, and I couldn't have achieved my goals without their expertise. Thank you for all their services.</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Vaishali Shivkumar</h6>
                                            <label>University Of Surrey, UK</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_3.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote>Excellent IELTS teaching, Guiding, Kind and helpful consultancy! Must Visit I'm sure that you'll get good response.</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Thilak Raj Varadarajan</h6>
                                            <label>University Of Wroclaw, Poland</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_4.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote>One of the best education consultants in Mysore. To speak honestly, they do justice, and do not charge. They help us right from beginning to shortlisting universities and selecting the best cities for studies. They manipulate and organise all our documents in system and help us to get admission to best universities till getting visa. I strongly recommend Mapeo for higher studies abroad.</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Arun Nagarajan</h6>
                                            <label>Bournemouth University, UK</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_5.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote>Mapeo Overseas Education Consultants  really helped me a lot with all the process beginning from the exams I was preparing for until I received all visa and other documents.</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Roshika Kothandapani</h6>
                                            <label>Trent University,  Canada</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_6.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote>Mapeo is one of the best consultancies in Mysore. I met with many other consultancies on abroad education and many were not that helpful. But the staff of Mapeo Overseas Consultants understood my requirements and budget and based on that they suggested me the best country that catered all my expectation. Accordingly, they helped in each and every step and guided me how to prepare and acquire the documents. I'm happy to say that I got my student visa to Ireland and I have joined National University of Ireland, Galway.</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Poojitha</h6>
                                            <label>National University Of Ireland Galway (Nuig)</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonials text-center">
                                    <div className="testimonial-content">
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img className="img-center" alt="testimonial-img" src={process.env.PUBLIC_URL + "/assets/images/testimonial/testimonial_7.jpg"} />
                                            </div>
                                        </div>
                                        <blockquote>The staff of Mapeo Overseas Education are highly  professionals and provide quick services. Due to their help I got admission from TU Dresden and TU Munich and finally I chose to join TU Munich in Germany</blockquote><br />
                                        <div className="testimonial-caption">
                                            <h6>Nithish S A</h6>
                                            <label>Technological University, Munich, Germany</label>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            {/*Testimonial Section End*/}

            <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData} />
        </div>
    )
}
export default Dashboard;