import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesFrance extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">France</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-9.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why France:</b>  France is one of the most popular destinations for international students. Among its major advantages are the high quality of education, the remarkably low tuition fees, and the enjoyable French way of life. France was found to be particularly good value for money, with tuition fees and living costs</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">being lower than in many other countries. It also has a large number of universities in the world’s top 100, and a high proportion of graduates find work within six months of leaving university.</p>
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">International students are allowed to work part-time jobs in France. However, the nature and timing of the work should not interfere with their studies. The restriction on the time is 20 hours per week, which means a student can work for 20 hours a week at part-time jobs and earn money to help themselves with some day-to-day costs. During semester breaks and holidays, they are allowed to work full time.</p>
                                                <p className="about-para">The good news is that France offers a post-study work permit (PSW), which allows recent graduates to stay in the country and look for work for up to two years. This can be a great opportunity to gain some international experience and learn more about the French way of life.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
