import React, { Component } from 'react';
import CustomNavbar from '../CustomNavbar';
import Banner from '../common/Banner';
import CountriesSidebar from './CountriesSidebar';

export default class CountriesIreland extends Component {

    render() {
        return (
            <div className="body_wrapper">

                <CustomNavbar mClass="menu_four" nClass="w_menu" />
                <Banner title="Countries" image="banners/about.jpeg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Ireland</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="ttm_single_image-wrapper">
                                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/country/country-6.jpg"} alt="single-img-eighteen" />
                                                </div>
                                            </div>
                                            <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                                <p className="about-para"><b>Why IRELAND:</b>  The duration of the Master degree is one year. It is currently home to 9 of the world’s top 10 pharmaceutical companies. Ireland is the largest exporter of software in the world and rates amongst the top five producers of pharmaceuticals and fine chemicals globally. The ever-increasing demands of</p>
                                            </div>
                                            <div className="col-md-12 justify paddingtop-1">
                                                <p className="about-para">this high technology economy have ensured that the higher education technological sector offers the highest and most advanced standards and opportunities available today.</p>
                                                <p className="about-para"> Some of the world’s biggest and best companies like Google, Facebook, HP, Accenture, Apple, Amazon, Uber, Pfizer, Abbott Laboratories, Tesco and etc. have located in Ireland.  The IT is booking in Ireland as the country’s laws have become flexible to MNCs.</p>
                                                <div className="section-title with-desc text-left clearfix">
                                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                                        <h5 className="title">Employbility</h5>
                                                    </div>
                                                </div>
                                                <p className="about-para">There are various part-time jobs available in all the sectors across hospitality/catering to call center to banking and finance and etc. With few exceptions, it is very unlikely that you will find a part-time job related to your course of study.</p>
                                                <p className="about-para">The student can work 20 hours per week during studies and 40 hours per week during vacations.</p>
                                                <p className="about-para">After Post-graduation, student will get post-work permit of 2 years.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <CountriesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
