import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Banner from '../components/common/Banner';
import PrototypeFooter from '../components/Footer/PrototypeFooter';
import FooterData from '../components/Footer/FooterData';

const Training = () => {
    return (
        <div className="body_wrapper">
            <CustomNavbar mClass="menu_four" nClass="w_menu" />
            <Banner title="Training" image="banners/about.jpeg" />
            <section className="get_started_three sec_pad">
                <div className="container">
                    <div className="sec_title text-center mb_70">
                        <h2 className="f_p f_size_30 l_height45 f_600 t_color3">MAPEO Overseas Coaching and Training</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="ttm_single_image-wrapper">
                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/about/about-2.jpg"} alt="single-img-eighteen" />
                            </div>
                        </div>
                        <div className="col-md-7 justify" style={{ margin: "auto" }}>
                            <p className="justify">With experienced staff in place, we provide excellent coaching and training for the following courses and examinations. Depending on the students’ preference, both online and offline classes are available</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>IELTS</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>GRE</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>PTE</li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="ttm-list ttm-list-style-icon">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>GMAT</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>TOEFL</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>GERMAN Language</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 paddingtop-2">
                            <p className="justify">This service entails a complete analysis of student profile, along with career counselling sessions. We will help the students chose the right universities and courses based on the analysis. These sessions are one – on- one and, personalised. They have a proven record of aiding the students improve their decision making abilities.</p>
                        </div>
                    </div>
                </div>
            </section>
            <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData} />
        </div>
    )
}
export default Training;